import React from "react";
import "./Footer.css";
import { assets } from "../../assets/assets";
const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <span className="logooo">Nsh7nha</span>
          <p>
            Discover the convenience of PlayStation gift cards, your gateway to
            the latest games, add-ons, and PlayStation Plus subscriptions.
            Instantly redeemable and perfect for gamers of all ages, our gift
            cards offer a seamless way to enhance your PlayStation experience.
          </p>
          <div className="footer-social-icons">
            <img src={assets.facebook_icon} alt="" />
            <img src={assets.twitter_icon} alt="" />
            <img src={assets.linkedin_icon} alt="" style={{ width: "50px" }} />
          </div>
        </div>
        <div className="footer-content-center">
          <h2>Company</h2>
          <ul>
            <li>Home</li>
            <li>Store</li>
            <li>Contact</li>
            <li>privacy policy</li>
          </ul>
        </div>
        <div className="footer-content-right">
          <h2>Get In Touch</h2>
          <ul>
            <li>+971-50-607-1847</li>
            <li>contact@gmail.com</li>
          </ul>
        </div>
      </div>
      <hr />
      <p className="footer-copyright">
        © 2024 PlayStation Card Store. All rights reserved. Trademarks are the
        property of their respective owners.
      </p>
    </div>
  );
};

export default Footer;
