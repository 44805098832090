import React, { useContext, useState } from "react";
import { assets } from "../../assets/assets";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { StoreContext } from "../Context/StoreContext";

export default function Navbar({ setShowLogin }) {
  const [menu, setMenu] = useState("Home");
  const { token, setToken, totalAmount } = useContext(StoreContext);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    setToken("");
    navigate("/");
  };
  return (
    <div className="navbar-header">
      <Link to="/">
        <img src={assets.logo} href='' className="logo" alt=""/>
      </Link>
      <ul className="navbar-menu-header">
        <Link
          to="/"
          onClick={() => setMenu("Home")}
          className={menu === "Home" ? "active" : ""}
        >
          Home
        </Link>
        <a
          href="#explore-store"
          onClick={() => setMenu("Store")}
          className={menu === "Store" ? "active" : ""}
        >
          Store
        </a>
        <a
          href="#app-download"
          onClick={() => setMenu("mobile-app")}
          className={menu === "mobile-app" ? "active" : ""}
        >
          mobile-app
        </a>
        <a
          href="#footer"
          onClick={() => setMenu("Contact-us")}
          className={menu === "Contact-us" ? "active" : ""}
        >
          Contact us
        </a>
      </ul>
      <div className="navbar-right-header">
        <a
          href="https://wa.me/971506071847"
          title="Chat with Us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={assets.coloredWhatsapp} alt="WhatsApp" />
        </a>
        <div className="navbar-search-icon">
          <Link to="/cart">
            <img src={assets.basket_icon} alt="Cart" />
          </Link>
          {totalAmount === 0 ? <></> : <div className="dot"></div>}
        </div>
        {token ? (
          <div className="navbar-profile">
            <img src={assets.profile_icon} alt="Profile" />
            <ul className="navbar-profile-dropdown">
              <li onClick={logout}>
                <img src={assets.logout_icon} alt="Logout" />
                <p>Logout</p>
              </li>
            </ul>
          </div>
        ) : (
          <button onClick={() => setShowLogin(true)}>Sign in</button>
        )}
      </div>
    </div>
  );
}
