import React from "react";
import { assets } from "../../assets/assets";
import "./AppDownload.css";

const AppDownload = () => {
  return (
    <div className="app-download" id="app-download">
      <p>
        For Better Experience Download <br /> Nsh7nha App
      </p>
      <div className="app-download-platforms">
        <div>
          <img src={assets.play_store} alt="Play Store" />
          <p>(Soon)</p>
        </div>
        <div>
          <img src={assets.app_store} alt="App Store" />
          <p>(Soon)</p>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
