import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. Your transaction has been completed.</p>
      <button onClick={handleBackToHome} style={buttonStyle}>
        Back to Website
      </button>
    </div>
  );
};

const buttonStyle = {
  marginTop: "20px",
  padding: "10px 20px",
  fontSize: "16px",
  color: "#fff",
  backgroundColor: "#007bff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default SuccessPage;
