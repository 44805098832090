import basket_icon from "./shopping-cart.svg";
import logo from './logo.png'
import search_icon from "./search_icon.png";
import whatsapp from "./whatsapp.png";
import coloredWhatsapp from "./whatsappColored .png";
import apple from './apple.png'
import google from "./search.png";


import add_icon_white from "./add_icon_white.png";
import add_icon_green from "./add_icon_green.png";
import remove_icon_red from "./remove_icon_red.png";
import app_store from "./app_store.png";
import play_store from "./play_store.png";
import linkedin_icon from "./linkedin-icon.png";
import facebook_icon from "./facebook_icon.png";
import twitter_icon from "./twitter_icon.png";
import cross_icon from "./cross_icon.png";
import selector_icon from "./selector_icon.png";
import online_shopping from "./online-shopping.png";
import profile_icon from "./user.jpeg";
import bag_icon from "./bag_icon.png";
import logout_icon from "./logout_icon.png";
import parcel_icon from "./parcel_icon.png";



export const assets = {
  logo,
  whatsapp,
  coloredWhatsapp,
  basket_icon,
  search_icon,
  online_shopping,
  add_icon_green,
  add_icon_white,
  remove_icon_red,
  app_store,
  play_store,
  linkedin_icon,
  facebook_icon,
  twitter_icon,
  cross_icon,
  selector_icon,
  profile_icon,
  logout_icon,
  bag_icon,
  parcel_icon,
  apple,
  google
};

