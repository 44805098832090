import React from 'react'
import './Header.css'

 const Header = () => {
  return (
    <div className="header">
      <div className="header-img">
        <img src='../.../../../../public/fc25.avif' alt="" />
      </div>
    </div>
  );
}

export default Header;