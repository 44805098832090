import React, { useContext } from "react";
import "./CardsDisplay.css";
import { StoreContext } from "../Context/StoreContext";
import CardItem from "../cardItems/CardItem";
const CardsDisplay = ({ category }) => {
  const { cards_list } = useContext(StoreContext);
  return (
    <div className="cards-display" id="cards-display">
      <h2> Top PNS </h2>
      <div className="cards-display-list">
        {cards_list.map((item) => {
          if (category === 0 || category === item.country_id) {
            return (
              <CardItem
                key={item.id}
                id={item.id}
                title={item.title}
                description={item.description}
                price={item.price}
                image={item.image}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default CardsDisplay;
