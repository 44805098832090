import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./CardPage.css";
import { StoreContext } from "../../components/Context/StoreContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CardPage = ({ setShowLogin }) => {
  const { cardId } = useParams();
  const { cards_list, addToCart, url } = useContext(StoreContext);
  const card = cards_list.find((c) => c.id.toString() === cardId);
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!card) {
    return <p>Card not found</p>;
  }

  const handleAddToCart = () => {
    if (token) {
      const quantity = 1;
      addToCart(card.id, quantity);
      toast.success("Item added to cart!");
    } else {
      setShowLogin(true); // Show login popup if not logged in
    }
  };

  return (
    <div className="card-page">
      <div className="card-container">
        <div className="card-image">
          <img src={url + "/storage/" + card.image} alt={card.name} />
        </div>
        <div className="card-details">
          <h2>{card.name}</h2>
          <p className="card-price">EGP {card.price.toLocaleString()}</p>
          <p className="card-availability">Instant Code Delivery</p>
          <button className="add-to-cart-btn" onClick={handleAddToCart}>
            ADD TO CART
          </button>
          <div className="card-info">
            <h3>Card Description</h3>
            <p>{card.description}</p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CardPage;
