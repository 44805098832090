import React, { useContext } from "react";
import "./StoreList.css"; // Import the new CSS file
import { StoreContext } from "../Context/StoreContext";

const StoreList = ({ category, setCategory }) => {
  const { countriesList, url } = useContext(StoreContext);
  return (
    <div className="explore-store" id="explore-store">
      <h1>Store Categories</h1>
      <div className="explore-store-list">
        {countriesList.map((item) => (
          <div
            key={item.id}
            onClick={() =>
              setCategory((prev) => (prev === item.id ? 0 : item.id))
            }
            className={`explore-store-list-item ${
              category === item.id ? "active" : ""
            }`}
          >
            <img
              src={`${url}/storage/${item.flag}`}
              alt=""
              className={`store-image ${category === item.id ? "active" : ""}`}
            />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
      <hr />
    </div>
  );
};

export default StoreList;
