import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./CardItem.css";
import { StoreContext } from "../Context/StoreContext";

const CardItem = ({ id, title, price, description, image }) => {
  const { url } = useContext(StoreContext);
  return (
    <Link to={`/card/${id}`} className="card-item-link">
      <div className="card-item">
        <div className="card-item-img-container">
          <img
            className="card-item-img"
            src={`${url}/storage/${image}`}
            alt={title}
          />
        </div>
        <div className="card-item-info">
          <p className="card-item-name">{title}</p>
          <div className="card-item-name-shopping">
            <p className="card-item-price">EGP {price}</p>
            {/* <img src={assets.online_shopping} alt="" /> */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardItem;
