import React, { useContext } from "react";
import "./Cart.css";
import { StoreContext } from "../../components/Context/StoreContext";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const Cart = () => {
  const {
    cartItems = {}, // Default to empty object if undefined
    removeFromCart,
    setQuantity,
    totalAmount,
    url,
    token,
    cards_list = [], // Default to empty array if undefined
  } = useContext(StoreContext);

  const handleQuantityChange = (itemId, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    setQuantity(itemId, newQuantity);
  };
const makePayment = async () => {
  console.log("card items : ", cartItems);
  const cart_ids = Object.entries(cartItems).map(([key, { id }]) => id);

  console.log("ids:", cart_ids);

  try {
    // Post request to /api/orders
    const orderResponse = await axios.post(
      `${url}/api/orders`,
      { cart_ids },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!orderResponse.data.success) {
      throw new Error("Network response was not ok");
    } else {
      console.log(orderResponse.data);
      const orderId = orderResponse.data.data.id;
      console.log("id ", orderId);

      // Fetch payment session using the orderId
      const paymentResponse = await axios.get(
        `${url}/api/payments/checkout/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Payment response:", paymentResponse);

      // Redirect to the payment URL received from the backend
      const paymentUrl = paymentResponse.data.data.url;
      window.location.href = paymentUrl;
    }
  } catch (error) {
    console.error(
      "Error during payment:",
      error.response ? error.response.data : error.message
    );
  }
};

  const hasItems = Object.keys(cartItems).length > 0;

  return (
    <div className="shopping-cart-container">
      <h2 className="shopping-cart-header">Cart</h2>
      <div className="cart-content">
        <ul className="shopping-cart-list">
          {Object.entries(cartItems).map(([itemId, { quantity }]) => {
            const card = cards_list.find(
              (card) => card.id.toString() === itemId
            );

            if (!card) {
              return <React.Fragment key={itemId} />;
            }
            return (
              <li key={itemId} className="shopping-cart-item">
                <div className="item-details">
                  <img
                    src={`${url}/storage/${card.image}`}
                    alt={card.name}
                    className="item-image"
                  />
                  <div className="item-description">
                    <span className="item-name">{card.name}</span>
                    <p className="item-price">
                      EGP {card.price.toLocaleString()}
                    </p>
                  </div>
                </div>
                <div className="item-quantity-actions">
                  <select
                    value={quantity}
                    onChange={(e) => handleQuantityChange(itemId, e)}
                    className="item-quantity-select"
                  >
                    {[...Array(10).keys()].map((i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  <button
                    className="remove-button"
                    onClick={() => removeFromCart(itemId)}
                  >
                    Remove
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="cart-summary">
          {hasItems && (
            <>
              <div className="cart-total">
                Total: {totalAmount.toLocaleString()} EGP
              </div>
              <button onClick={makePayment} className="checkout-btn">
                Checkout
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
