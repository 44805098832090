import React from "react";
import { useNavigate } from "react-router-dom";

const CancelPage = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div>
      <h1>Payment Canceled</h1>
      <p>
        Your payment has been canceled. You can return to the store to continue
        shopping.
      </p>
      <button onClick={handleBackToHome} style={buttonStyle}>
        Back to Website
      </button>
    </div>
  );
};

const buttonStyle = {
  marginTop: "20px",
  padding: "10px 20px",
  fontSize: "16px",
  color: "#fff",
  backgroundColor: "#007bff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default CancelPage;
