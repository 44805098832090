import React, { useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import Cart from "../src/pages/cart/Cart.jsx";
import Footer from "./components/Footer/Footer";
import LoginPopup from "./components/LoginPopup/LoginPopup";
import CardPage from "./pages/CardPage/CardPage";
import SuccessPage from "./pages/actionOfPayment/success.jsx"; // Import SuccessPage
import CancelPage from "./pages/actionOfPayment/cancel.jsx"; // Import CancelPage

const App = () => {
  const [showLogin, setShowLogin] = useState(false);

  return (
    <>
      {showLogin && <LoginPopup setShowLogin={setShowLogin} />}{" "}
      {/* Conditional rendering of LoginPopup */}
      <div className="app">
        <Navbar setShowLogin={setShowLogin} />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route
              path="/card/:cardId"
              element={<CardPage setShowLogin={setShowLogin} />}
            />{" "}
            <Route path="/success" element={<SuccessPage />} />{" "}
            <Route path="/cancel" element={<CancelPage />} />{" "}
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
