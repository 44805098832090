import React, { useState, useContext } from "react";
import axios from "axios";
import { StoreContext } from "../../components/Context/StoreContext";
import { useNavigate } from "react-router-dom";
import "./Verification.css";

const Verification = ({ email, setShowLogin }) => {
  const { setToken, url, setCartItems } = useContext(StoreContext);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onChangeHandler = (event) => {
    setOtp(event.target.value);
  };

  const onVerify = async (event) => {
    event.preventDefault();

    const verifyUrl = `${url}/api/email/verification/store`;

    try {
      const response = await axios.post(
        verifyUrl,
        { email, otp },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.success) {
        const token = response.data.access_token;
        setToken(token);
        localStorage.setItem("token", token);

        // Fetch and update cart items
        await loadCartItems(token);

        setShowLogin(false);
        navigate("/"); // Redirect to home or dashboard

        // Trigger a page reload to ensure all components are updated
        window.location.reload();
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Verification failed");
    }
  };

  const loadCartItems = async (token) => {
    try {
      const response = await axios.get(`${url}/api/carts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data.data;
      const items = data.reduce((acc, item) => {
        acc[item.card_id] = { id: item.id, quantity: item.quantity };
        return acc;
      }, {});
      setCartItems(items);
    } catch (error) {
      console.error("Failed to load cart items", error);
    }
  };

  return (
    <div className="verification-popup">
      <form onSubmit={onVerify} className="verification-popup-container">
        <div className="verification-popup-title">
          <h2>Email Verification</h2>
        </div>
        <p className="verification-description">
          We've sent a verification code to your email: <strong>{email}</strong>
          . Please enter the code below to verify your account.
        </p>
        <div className="verification-popup-input">
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={onChangeHandler}
            required
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit" className="verification-button">
          Verify
        </button>
      </form>
    </div>
  );
};

export default Verification;
