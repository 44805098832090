import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const StoreContext = createContext(null);

const StoreContextProvider = (props) => {
  const url = "https://api.nsh7nha.com"; // Use an environment variable for the URL
  const [cartItems, setCartItems] = useState({});
  const [cards_list, setCardList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null); // Added error state

  const handleError = (error) => {
    console.error(error);
    setError(error.message || "An error occurred");
    setLoading(false);
  };

  const addToCart = async (itemId, quantity) => {
    if (!token) return;

    try {
      const response = await axios.post(
        `${url}/api/carts`,
        { card_id: itemId, quantity },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const id = response.data.data.id;

      setCartItems((prev) => ({
        ...prev,
        [itemId]: { id, quantity },
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const removeFromCart = async (itemId) => {
    setCartItems((prevItems) => {
      const currentQuantity = prevItems[itemId]?.quantity;
      const cartId = prevItems[itemId]?.id;

      if (currentQuantity <= 1) {
        if (token && cartId) {
          axios
            .delete(`${url}/api/carts/${cartId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(() => {
              const { [itemId]: removedItem, ...remainingItems } = prevItems;
              setCartItems(remainingItems);
            })
            .catch(handleError);
        } else {
          // Just remove item if no token or cartId
          const { [itemId]: removedItem, ...remainingItems } = prevItems;
          setCartItems(remainingItems);
        }
      } else {
        if (token && cartId) {
          axios
            .put(
              `${url}/api/carts/${cartId}`,
              { card_id: itemId, quantity: currentQuantity - 1 },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(() => {
              setCartItems({
                ...prevItems,
                [itemId]: {
                  ...prevItems[itemId],
                  quantity: currentQuantity - 1,
                },
              });
            })
            .catch(handleError);
        }
      }
      return prevItems;
    });
  };

  const loadCartItems = async (token) => {
    try {
      const response = await axios.get(`${url}/api/carts`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data.data;
      const items = data.reduce((acc, item) => {
        acc[item.card_id] = { id: item.id, quantity: item.quantity };
        return acc;
      }, {});

      setCartItems(items);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCardList = async () => {
    try {
      const response = await axios.get(`${url}/api/cards`);
      
      if (response.data.success) {
        setCardList(response.data.data);
      } else {
        console.log("Fetch card list was not successful.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${url}/api/countries`);
        
      if (response.data.success) {
        setCountriesList(response.data.data);

      } else {
        console.log("Fetch countries list was not successful.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const totalAmount = Object.entries(cartItems || {}).reduce(
    (total, [itemId, { quantity }]) => {
      if (!itemId || quantity === undefined) {
        console.error("Invalid item data:", { itemId, quantity });
        return total;
      }
      const card = cards_list.find((card) => card.id.toString() === itemId);
      return total + (card ? card.price * quantity : 0);
    },
    0
  );

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        await fetchCardList();
        await fetchCountries();
        if (token) {
          setToken(token);
          await loadCartItems(token);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }

    };

    loadData();

  }, []);

  const setQuantity = (itemId, quantity) => {
    setCartItems((prevItems) => {
      const updatedItems = {
        ...prevItems,
        [itemId]: { ...prevItems[itemId], quantity },
      };

      if (token) {
        const cartId = prevItems[itemId]?.id;
        if (cartId) {
          axios
            .put(
              `${url}/api/carts/${cartId}`,
              { card_id: itemId, quantity },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(() => {
              // No need to update state again as it is already updated
            })
            .catch(handleError);
        }
      }

      return updatedItems;
    });
  };

  const contextValue = {
    cards_list,
    cartItems,
    addToCart,
    removeFromCart,
    token,
    setToken,
    totalAmount,
    url,
    setQuantity,
    countriesList,
    loading, // Provide loading state
    setLoading,
    error, // Provide error state
  };

  return (
    <StoreContext.Provider value={contextValue}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
