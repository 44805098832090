import React, { useContext, useState } from "react";
import "./LoginPopup.css";
import axios from "axios";
import { StoreContext } from "../../components/Context/StoreContext";
import Verification from "./Verification";
import { assets } from "../../assets/assets";


const LoginPopup = ({ setShowLogin }) => {
  const { setToken, url } = useContext(StoreContext);
  const [currState, setCurrState] = useState("Sign Up");
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showVerification, setShowVerification] = useState(false);

  const onChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((data) => ({ ...data, [name]: value }));
  };

  const onRegister = async (event) => {
    event.preventDefault();
    const registerUrl = `${url}/api/register`;

    try {
      const response = await axios.post(registerUrl, data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        const verifyUrl = `${url}/api/email/verification/create`;
        const verifyResponse = await axios.post(
          verifyUrl,
          { email: data.email },
          { headers: { "Content-Type": "application/json" } }
        );

        if (verifyResponse.data.success) {
          setShowVerification(true);
        } else {
          setErrorMessage("Verification email could not be sent.");
        }
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Something went wrong");
    }
  };

  const onLogin = async (event) => {
    event.preventDefault();
    const loginUrl = `${url}/api/login`;

    try {
      const response = await axios.post(loginUrl, data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        setToken(response.data.access_token);
        localStorage.setItem("token", response.data.access_token);
        setShowLogin(false);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Something went wrong");
    }
  };

  const handleGoogleLogin = () => {
    // window.location.href = `${url}/auth/google`;
  };

  const handleAppleLogin = () => {
    // window.location.href = `${url}/auth/apple`;
  };

  return (
    <div className="login-popup">
      {showVerification ? (
        <Verification email={data.email} setShowLogin={setShowLogin} />
      ) : (
        <form
          onSubmit={currState === "Sign Up" ? onRegister : onLogin}
          className="login-popup-container"
        >
          <div className="login-popup-title">
            <h2>{currState}</h2>
            <img
              onClick={() => setShowLogin(false)}
              src={assets.cross_icon}
              alt=""
            />
          </div>
          <div className="login-popup-inputs">
            {currState === "Login" ? null : (
              <input
                name="name"
                onChange={onChangeHandler}
                value={data.name}
                type="text"
                placeholder="Your name"
                required
              />
            )}
            <input
              name="email"
              onChange={onChangeHandler}
              value={data.email}
              type="email"
              placeholder="Your email"
              required
            />
            <input
              name="password"
              onChange={onChangeHandler}
              value={data.password}
              type="password"
              placeholder="password"
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit">
            {currState === "Sign Up" ? "Create account" : "Login"}
          </button>
          <div className="login-popup-condition">
            <input type="checkbox" required />
            <p>By continuing, I agree to the terms of use & privacy policy.</p>
          </div>
          {currState === "Login" ? (
            <p>
              Create a new account?{" "}
              <span onClick={() => setCurrState("Sign Up")}>Click here</span>
            </p>
          ) : (
            <p>
              Already have an account?{" "}
              <span onClick={() => setCurrState("Login")}>Login here</span>
            </p>
          )}
          <div className="login-popup-social">
            <img
              src={assets.google}
              alt="Google"
              className="social-icon google-icon"
              onClick={handleGoogleLogin}
            />
            <img
              src={assets.apple}
              alt="Apple"
              className="social-icon apple-icon"
              onClick={handleAppleLogin}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default LoginPopup;
