import React, { useState, useEffect, useContext } from "react";
import "./Home.css";
import Header from "../../components/Header/Header";
import StoreList from "../../components/StoreList/StoreList";
import CardsDisplay from "../../components/CardsDisplay/CardsDisplay";
import AppDownload from "../../components/AppDownload/AppDownload";
import { StoreContext } from "../../components/Context/StoreContext";
import { assets } from "../../assets/assets";

export const Home = () => {
  const [category, setCategory] = useState(0);
  const [loading, setLoading] = useState(true);
  const { cards_list, countriesList } = useContext(StoreContext);

  useEffect(() => {
    if (cards_list.length > 0 || countriesList.length > 0) {
      setLoading(false);
    }
  }, [cards_list, countriesList]);

  if (loading) {
    return (
      <div className="loading-container">
        <img src={assets.logo} alt="Logo" className="loading-logo" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <StoreList category={category} setCategory={setCategory} />
      <CardsDisplay category={category} />
      <AppDownload />
    </>
  );
};
